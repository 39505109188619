export default {
  aaCheckout: {
    ancillary: {
      baggage: {
        baggageExperience: {
          budnleInclude: '常飞客包含 {1} 公斤托运行李限额。',
          depart: '出发航班',
          handCarryToolTipLabel:
            '这是您可以带入飞机的行李。您须将其存放入客舱行李架中。',
          return: '往返航班',
          sportsEquipmentLabel: '我需要运输体育器材',
          buttonTitle: '添加行李限额',
          submitButtonTitle: '完成',
          total: '总价',
          chooseSelection: '选择行李',
          handCarry: '手提包',
          title: '行李',
          summaryButtonText: '变更',
          handCarryToolTipTitle: '什么是手提行李？',
          notificationMessage: '我们已为您预先选定行李',
          checkBoxTitle: '为全部乘客选择一样的行李限额',
          sportsEquip: '运动装备',
          includedIcon: '包含于增值服务套餐中',
          includedBaggage:
            '提前预订价格最优惠，所示价格适用于您行程中的全部航班。',
          bundle: '套餐',
          included: '包括',
          handCarrySizeDesc: '每件行李尺寸不得超过56 x 36 x 23厘米',
          tileDescription:
            '提前预订价格最优惠，所示价格适用于您行程中的全部航班。',
          none: '无',
          priceDescription: '行李限额总费用',
          checkedBaggage: '托运行李',
          nextFlightButtonTitle: '下趟航班',
        },
      },
      insurance: {
        benefitsSubtitle: '{total_benefits} benefits大福利',
        continueWithInsuranceCta: '添加保险',
        continueWithoutInsuranceCta: '不添加保险，继续下一步',
        priceSubtitle: '适用{total_pax}名乘客',
        headerTitle: '保险',
        readMoreText: '了解详情',
        paxCount: '适用{paxcount}名乘客',
        headerMessage: '防止旅行意外情况。',
        termsText: '选择保险即代表我同意',
        termsAndConditionCloseButtonText: '关闭',
        discountedPercentage: '{discountedPercent}%折扣',
        termsLinkText: '条款与条件',
      },
      wheelchairSelector: {
        unavailablePrimaryBtn: '在没有轮椅服务下继续',
        nextFlightButtonTitle: '下趟航班',
        pwdNoteTitle: '什么是PWD ID？',
        pwdNote:
          'PWD ID是残疾人身份证明。您需要在柜台办理值机时出示此证件以确认其有效性。我们将在机场收取适用的费用。',
        buttonTitle: '申请',
        pwdSubTitle: '如果您有残疾人身份证，我们将免除轮椅服务费用。',
        cardDescription: '特殊需求乘客额外支持',
        needWheelchair: '我需要轮椅',
        unavailable: '不可选',
        title: '特殊帮助',
        wheelchairAvailabilityErrorTitle: '抱歉，出现错误！',
        submitButtonTitle: '完成',
        unavailableSubTitle1:
          '由于无法提供为本次旅程选择的轮椅，我们已从您的预订中删除了该选项。',
        errorTextTitle: '无法预订特殊帮助',
        available: '剩余{0}个',
        pwdFooter:
          '在特殊情况下，我们可能接受托运移除电池的电动轮椅或行动辅助器具（乘客需自行移除电池），且轮椅或行动辅助器具的重量限额为 85 公斤 。',
        readMore: '了解详情',
        pwdTitle: '输入您的PWD ID(可选)',
        ownWheelchair: '我自己有轮椅',
        noWheelchairOptionLabel: '我有自己的轮椅，不需要帮助',
        waiveWheelchairFee: '已免除轮椅费',
        unavailableSubTitle2:
          '您可以在没有轮椅的情况下继续前往支付页面，或选择其他可提供轮椅服务的航班。',
        pwdTextLabel: '身份证号码',
        unavailableSecondaryBtn: '变更航班',
        errorTextMessage: '可于机场进行申请，先到先得',
        wheelchairAvailabilityErrorMessage:
          '加载特殊帮助时出现问题，请刷新页面！',
        wheelchairFooterMessage: '机场供应轮椅数量有限，先到先得',
        priceDescription: '轮椅总费用',
        refresh: '更新',
        backToAddons: '返回增值服务',
        unavailableTitle: '无法提供轮椅服务',
        total: '总价',
        summaryButtonText: '变更',
      },
    },
  },
};
