import i18n from '@/helper/i18n';
import { makeObservable, observable, action } from 'mobx';
import { userStore } from '@/store/base';
import { CommonApi } from '@/api';
import { DEFAULT_NATIONALITY, DEFAULT_TRAVEL_DOC_TYPES } from './consts';
import { getTravelDocTypesForGuest, isDocMandatory } from './parse/travel-docs';
import { getGuestDisplayName } from './parse/guests';
import { applyAdditionalValidation } from './parse/validation';

import { transform } from './parse';

const t = i18n.chain;

const genderTitleMap = {
  Male: 'MR',
  Female: 'MS',
};

export default class GuestsAndContactStore {
  @observable familyAndFriends: AACheckout.FamilyAndFriend[] = [];
  @observable guests: AACheckout.Passenger[] = [];
  @observable contact = {
    countryCode: '',
    email: '',
    familyName: '',
    givenName: '',
    mobileNumber: '',
    salutation: 'MR',
  };
  @observable countries: any[] = [];
  @observable gender: any = [];
  @observable salutation: any = [];
  @observable departureTime = '';

  @observable guestsLimit = {
    adult: 1,
    child: 0,
    infant: 0,
  };

  @observable defaultContact = {
    countryCode: '',
    email: '',
    familyName: '',
    givenName: '',
    mobileNumber: '',
    salutation: 'MR',
  };

  @observable contactHasError = false;
  @observable defaultContactChecked = false;
  @observable apiKey = '';
  @observable bigPointsCalculationToken = '';

  loggedInHandled = false;
  contactFormRef = null;
  guestsFormRef = {};

  get paxTitles() {
    const paxTitles: AACheckout.PaxTitle[] = this.guests
      .filter((g) => g.paxType !== 'infant')
      .map((g) => {
        let givenName = g.formData.givenName;
        let englishGivenName = g.formData.givenName;
        let familyName = g.formData.familyName;

        if (!familyName && !givenName) {
          givenName = `${t.flights.checkout.guestDetailsForm[g.paxType]} ${
            g.subSeq
          }`;
          englishGivenName = `${g.paxType} ${g.subSeq}`;
          familyName = '';
        }

        const displayName = `${givenName} ${familyName}`;
        const englishName = `${englishGivenName} ${familyName}`;

        const shortName =
          englishName.split('')[0].toUpperCase() +
          (englishName.split('')[1] || '').toUpperCase();

        return {
          givenName,
          familyName,
          shortName,
          displayName,
          type: g.paxType,
          withInfant: !!g.infantRef,
          paxId: g.referenceId,
        };
      });

    return paxTitles;
  }

  constructor() {
    makeObservable(this);
  }

  @action
  updateData = async (originalPageData, tripsInfo) => {
    const {
      countries,
      gender,
      salutation,
      guests,
      guestsLimit,
      familyAndFriends,
      departureTime,
      bigPointsCalculationToken,
    } = await transform(originalPageData, tripsInfo);

    this.departureTime = departureTime;
    this.countries = countries;
    this.salutation = salutation;
    this.gender = gender;
    this.guests = guests;
    this.guestsLimit = guestsLimit;
    this.familyAndFriends = familyAndFriends;
    this.bigPointsCalculationToken = bigPointsCalculationToken;

    this.selectFirstGuest();
  };

  @action
  selectFirstGuest = async () => {
    const firstOne = this.familyAndFriends.find((f) => f.isSelected);
    this.onGuestsSelected([firstOne], firstOne);
  };

  @action
  loggedInUpdated = async () => {
    if (this.loggedInHandled) return;

    this.loggedInHandled = true;

    this.apiKey = await CommonApi.SSOAuth.getApiKey();

    const findCountries = this.countries.filter(
      (m) => m.dialingCode.replace('+', '') === userStore.user.dialingCode,
    );

    let countryCode = '';
    let mobileNumber = '';
    if (findCountries.length === 1) {
      mobileNumber = userStore.user.phoneNumber;
      countryCode = findCountries[0].value;
    }

    this.defaultContact = {
      countryCode,
      email: userStore.user.username,
      familyName: userStore.user.lastName,
      givenName: userStore.user.firstName,
      mobileNumber,
      salutation: userStore.user.title,
    };

    this.defaultContactChecked = true;
  };

  @action
  handleContactField = async (e) => {
    if (e.trigger || e.trigger === undefined) {
      this.contact = {
        ...this.contact,
        ...e,
      };
    }

    this.validateContactForm();
  };

  @action
  onDefaultChecked = async (e) => {
    this.defaultContactChecked = false;
  };

  @action
  handleGuestField = async (e, guestData: AACheckout.Passenger) => {
    if (e.trigger || e.trigger === undefined) {
      // Special handling travelling with
      if (e.travellingWith) {
        guestData.formData.travellingWith &&
          (this.guests.find(
            (g) => g.referenceId === guestData.formData.travellingWith,
          ).infantRef = '');
        this.guests.find((g) => g.referenceId === e.travellingWith).infantRef =
          guestData.referenceId;
      }

      guestData.triggerBigpointsCalc = 'givenName' in e || 'familyName' in e;

      guestData.formData = {
        ...guestData.formData,
        ...e,
      };
    }
  };

  private setPassengerDocumentInfo(
    passenger: AACheckout.Passenger,
    currentGuest: AACheckout.FamilyAndFriend,
  ) {
    const hasPassport = passenger.supportedDocTypes.some(
      (docType) => docType.type === 'passport',
    );

    if (hasPassport) {
      passenger.formData.type = 'passport';
      passenger.formData.docNumber = currentGuest.docNumber;
      passenger.formData.expiryDate = currentGuest.expiryDate;
    }
  }

  @action
  onGuestsSelected = async (
    _selectedGuests: AACheckout.FamilyAndFriend[],
    currenGuest: AACheckout.FamilyAndFriend,
  ) => {
    if (currenGuest.isSelected) {
      const findOne = this.guests.find(
        (s) => s.paxType === currenGuest.paxType && !s.syncId,
      );

      findOne.email = currenGuest.email;
      findOne.loyaltyId = currenGuest.loyaltyId;
      findOne.loyaltyTier = currenGuest.loyaltyTier;
      findOne.syncId = currenGuest.bigMemberId || currenGuest.docNumber;
      findOne.formData.givenName = currenGuest.givenName;
      findOne.formData.familyName = currenGuest.surname;
      findOne.formData.nationality =
        currenGuest.nationality || DEFAULT_NATIONALITY;
      findOne.formData.gender = currenGuest.gender;
      findOne.formData.dateOfBirth = currenGuest.dateOfBirth;
      findOne.formData.bigMemberId = currenGuest.bigMemberId;

      this.setPassengerDocumentInfo(findOne, currenGuest);

      findOne.triggerBigpointsCalc = true;
      findOne.validateOnload = true;
    } else {
      const findOne = this.guests.find(
        (s) =>
          s.syncId === currenGuest.bigMemberId ||
          s.syncId === currenGuest.docNumber,
      );

      findOne.email = '';
      findOne.loyaltyId = '';
      findOne.loyaltyTier = '';
      findOne.syncId = '';
      findOne.formData.givenName = '';
      findOne.formData.familyName = '';
      findOne.formData.nationality = DEFAULT_NATIONALITY;
      findOne.formData.gender = currenGuest.gender;
      findOne.formData.dateOfBirth = '';
      findOne.formData.type =
        findOne.supportedDocTypes.length > 0
          ? findOne.supportedDocTypes[0].type
          : '';
      findOne.formData.docNumber = '';
      findOne.formData.expiryDate = '';
      findOne.formData.bigMemberId = '';
      findOne.validateOnload = true;
      findOne.triggerBigpointsCalc = true;
    }

    this.guests = JSON.parse(JSON.stringify(this.guests));
  };

  @action
  validateContactForm = async () => {
    if (this.contactFormRef && this.contactFormRef.current) {
      await this.contactFormRef.current.validateForm();
      await this.contactFormRef.current.submitForm();
      this.contactHasError = !this.contactFormRef.current.isValid;
    } else {
      this.contactHasError = true;
    }
  };

  @action
  validateForm = async () => {
    await this.validateContactForm();

    await Promise.all(
      Object.getOwnPropertyNames(this.guestsFormRef).map(async (index) => {
        const formRef = this.guestsFormRef[index] || {};

        await formRef.personalInfoRef?.current?.validateForm();
        await formRef.passportInfoRef?.current?.validateForm();
        await formRef.personalInfoRef?.current?.submitForm();
        await formRef.passportInfoRef?.current?.submitForm();
        const guest = this.guests[index];

        guest.hasError =
          guest.supportedDocTypes.length > 0
            ? !formRef.personalInfoRef?.current?.isValid ||
              !formRef.passportInfoRef?.current?.isValid
            : !formRef.personalInfoRef?.current?.isValid;

        const updatedGuestData =
          guest.supportedDocTypes.length > 0
            ? {
                ...formRef.personalInfoRef?.current?.values,
                type: formRef.passportInfoRef?.current?.values.type,
                expiryDate: formRef.passportInfoRef?.current?.values.expiryDate,
                docNumber: formRef.passportInfoRef?.current?.values.docNumber,
              }
            : { ...formRef.personalInfoRef?.current?.values };

        // Component returns a random number when there is no bigMemberId
        if (typeof updatedGuestData.bigMemberId === 'number') {
          updatedGuestData.bigMemberId = '';
        }

        guest.formData = updatedGuestData;

        if (this.guests[index].paxType === 'infant') {
          this.guests[index].formData.travellingWith =
            formRef.travellingWithRef?.current?.values.travellingWith;
        }
      }),
    );

    const formHasError =
      this.guests.some((g) => g.hasError) || this.contactHasError;

    const contactValues = this.contactFormRef?.current?.values;

    if (contactValues) {
      this.contact = {
        ...this.contact,
        ...contactValues,
      };
    }

    if (formHasError) {
      throw new Error(t.flights.checkout.errors.formError);
    }
  };

  applyAdditionalValidation = async () => {
    return applyAdditionalValidation(this.guests);
  };

  getDisplayName = (g: AACheckout.Passenger) => {
    return getGuestDisplayName(g);
  };

  composeCheckoutData() {
    const contactDetails = {
      title: this.contact.salutation,
      firstName: this.contact.givenName,
      middleName: '',
      lastName: this.contact.familyName,
      emailId: this.contact.email,
      contactNumber: this.contact.mobileNumber,
      countryCode: this.contact.countryCode,
      dialingCode: this.countries.find(
        (c) => c.value === this.contact.countryCode,
      ).dialingCode,
      notificationPreference: 'none',
    };

    const passengerDetails = this.guests.map((g) => {
      const pax: any = {
        firstName: g.formData.givenName,
        lastName: g.formData.familyName,
        dob: g.formData.dateOfBirth,
        nationality: g.formData.nationality,
        paxType: g.paxType,
        subPaxType: g.paxType,
        referenceId: g.referenceId,
        bigMemberId: g.formData.bigMemberId,
        title: genderTitleMap[g.formData.gender],
        gender: g.formData.gender,
        travelDocuments:
          g.supportedDocTypes.length > 0
            ? [
                {
                  docNumber: g.formData.docNumber,
                  type: g.formData.type,
                  expiryDate:
                    g.formData.type === 'id' ? null : g.formData.expiryDate,
                },
              ]
            : [],
      };

      if (g.formData.travellingWith) {
        pax.mappedToPax = g.formData.travellingWith;
      }

      return pax;
    });

    return {
      contactDetails,
      passengerDetails,
    };
  }

  getTravelDocsForGuest(supportedDocTypes): AACheckout.TravelDocTypes[] {
    const travelDocTypes = getTravelDocTypesForGuest(supportedDocTypes);

    return travelDocTypes;
  }

  isPassportMandatory(supportedDocTypes): boolean {
    const isMandatory = isDocMandatory(
      supportedDocTypes,
      DEFAULT_TRAVEL_DOC_TYPES[0],
    );

    return isMandatory;
  }
}
