import {
  convertMoneyToDefaultCurrency,
  traverseObj,
} from '@/helper/util/common';
import { CURRENCY_DEFAULT } from '@/helper/constants';

export class SeatParser {
  private pageData;
  private conversionRates;
  private baseCurrency;

  constructor(pageData, conversionRates) {
    this.pageData = pageData;
    this.conversionRates = conversionRates;
  }

  public parse() {
    const { ancillary: originalAncillary = {} } = this.pageData;

    const { seat = {} } = originalAncillary;
    const {
      startPrice = '',
      isPaxDependent,
      measure,
      data: jouneysAncillary,
    } = seat;

    const seatMapData: AACheckout.SeatMapData =
      this.getSeatMapData(jouneysAncillary);

    return {
      startPrice,
      isPaxDependent,
      measure,
      seatMapData,
    };
  }

  private getSeatMapData(jouneysAncillary): AACheckout.SeatMapData {
    const { designator: firstDesignator } = jouneysAncillary[0];
    const seatmap = {};
    let totalSegments = 0;

    for (const journeyIndex in jouneysAncillary) {
      const journey = jouneysAncillary[journeyIndex];
      const { ancillarylist: segmentList } = journey;
      for (const segment of segmentList) {
        const { designator, currency } = segment;
        totalSegments++;

        this.baseCurrency = currency;

        let newSegment = this.convertPrice(segment, [
          'price',
          'minPrice',
          'maxPrice',
          'rowPrice',
        ]);
        newSegment = this.convertNumber(segment, ['number']);

        seatmap[`${designator.departureStation}_${designator.arrivalStation}`] =
          {
            ...newSegment,
            currency: CURRENCY_DEFAULT,
            journeyType: journeyIndex === '0' ? 'depart' : 'return',
          };
      }
    }

    return {
      designator: firstDesignator,
      properties: {
        totalSegments,
      },
      seatmap,
    };
  }

  private convertNumber(obj, keys) {
    return traverseObj(obj, keys, (obj, prop) => {
      if (obj[prop] !== null && !isNaN(obj[prop])) {
        obj[prop] = Number(obj[prop]);
      }
    });
  }

  private convertPrice(obj, keys) {
    return traverseObj(obj, keys, (obj, prop) => {
      if (obj[prop] !== null && !isNaN(obj[prop])) {
        obj[prop] = convertMoneyToDefaultCurrency(
          Number(obj[prop]),
          this.baseCurrency,
          this.conversionRates,
        );
      }
    });
  }
}
