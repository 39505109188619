import { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import router from '@/router';
import { useStore } from '@/hooks';
import { HeaderLayout, RequireAuth, withError, Loading } from '@/components';
import { ROUTE_URL } from '@/helper/constants';
import GlobalAlert from '@/pages/common/components/global-alert';
import GlobalLoading from '@/pages/common/components/global-loading';

import './App.scss';

function App() {
  //init webstore
  const { webStore } = useStore();
  webStore.init();

  return (
    <div className="app">
      <Router>
        <Suspense fallback={<Loading fullScreen />}>
          <Routes>
            {router.routes.map((route) => {
              return (
                <Route
                  key={route.path}
                  element={route.layout ? <route.layout /> : <HeaderLayout />}
                >
                  <Route
                    path={route.path}
                    element={
                      route.isAuth === false ? (
                        <route.component />
                      ) : (
                        <RequireAuth>
                          <route.component />
                        </RequireAuth>
                      )
                    }
                    key={route.path}
                  >
                    {route.children?.map((childRoute) => (
                      <Route
                        key={childRoute.path}
                        path={childRoute.path}
                        element={<childRoute.component />}
                      />
                    ))}
                  </Route>
                </Route>
              );
            })}
            <Route
              path="*"
              element={<Navigate to={ROUTE_URL.ROOT} replace />}
            />
          </Routes>
        </Suspense>
      </Router>
      <GlobalAlert />
      <GlobalLoading />
    </div>
  );
}

export default withError(App);
