import { action, makeObservable, observable } from 'mobx';
import { transform } from './parse';

type TransferOption = '' | 'protected_by_airline' | 'self_transfer';

export default class FlightDetailsStore {
  @observable showTransferInfo = false;
  @observable showJourneyDetails = false;
  @observable transferOption: TransferOption = '';
  @observable journeyDetails: AACheckout.JourneyDetail[] = [];
  @observable journeys: AACheckout.Journey[] = [];
  @observable stationDetails: AACheckout.StationDetails = {};

  originalflightDetails = null;

  constructor() {
    makeObservable(this);
  }

  @action
  updateData = async (originalPageData) => {
    const { journeyDetails, journeys, stationDetails } = await transform(
      originalPageData,
      this.protectedByActionCallback,
      this.selfTransferActionCallback,
    );

    this.journeyDetails = journeyDetails;
    this.journeys = journeys;
    this.stationDetails = stationDetails;
    this.originalflightDetails = originalPageData.flightDetails;
  };

  @action
  toggleOnShowJourneyDetails = async () => {
    this.showJourneyDetails = true;
  };

  @action
  toggleOffShowJourneyDetails = async () => {
    this.showJourneyDetails = false;
  };

  @action
  protectedByActionCallback = async () => {
    this.showTransferInfo = true;
    this.transferOption = 'protected_by_airline';
  };

  @action
  selfTransferActionCallback = async () => {
    this.showTransferInfo = true;
    this.transferOption = 'self_transfer';
  };

  @action
  onKnowIt = async () => {
    this.showTransferInfo = false;
  };

  composeCheckoutData() {
    const bookingFlightDetails = {
      cartDetails: this.originalflightDetails.cartDetails,
      flightDetails:
        this.originalflightDetails.flightDetails ||
        this.originalflightDetails.flightDetails.flightDetails,
      priceChanged:
        this.originalflightDetails.priceChanged ||
        this.originalflightDetails.flightDetails.priceChanged,
    };

    return {
      bookingFlightDetails,
      tripType: this.journeyDetails.length > 1 ? 'R' : 'O',
    };
  }
}
