import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const LogConsole = {
  error(e) {
    //send to sentry
    /* Sentry.init({
      dsn: 'xxx',
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
    */

    console.error(e);
  },
  debug(e) {
    if (process.env.NODE_ENV === 'development') {
      console.log(e);
    }
  },
};
export default LogConsole;
