import { action, observable, makeObservable } from 'mobx';
import { HotelApi, StaticApi, CommonApi } from '@/api';
import { hotelSearchComponentStore, hotelGlobalStore } from '@/store/common';
import Config from '@/config';
import { LogConsole } from '@/helper/log';
import { userStore } from '@/store/base';
import { navigateWMPPage } from '@/helper/util';
import { transformHotelRoomsData } from '@/helper/hotel/hotel-rooms';
import { getOccupancyArray } from '@/helper/hotel/utils';
import { countNights } from '@/helper/hotel/utils';
import { transformHotelContentData } from '@/helper/hotel/hotel-content';
import { CANCELLATION_PENALTY_TYPES } from '@/helper/hotel/booking';
import { validate, deepMerge } from '@/helper/validator';
import { validationRules } from './validation-rules';
import i18n from '@/helper/i18n';
import {
  HOTEL_ORDER_TYPE_HOTEL,
  HOTEL_ORDER_ITEMS_ID,
} from '@/helper/constants';

const { chain: t } = i18n;

export default class HotelGuestDetailStore implements PageStore {
  @observable hotelId = '';
  @observable roomId = '';
  @observable rateId = '';
  @observable bedGroupId = '';
  @observable rateOptionId = '';

  @observable location = '';
  @observable countryCode = '';
  @observable hotelContent: Hotel.HotelContentInfo = null;
  @observable room = null;
  @observable rate = null;
  @observable totalNights = 1;
  @observable firstPenaltyEndDate = '';

  @observable showMessage = false;
  @observable message = '';
  @observable isLoading = true;
  @observable isLoadingBtn = false;
  @observable hasMissingFields = false;
  @observable validationResults = {};
  @observable hasError = false;
  @observable submitDisabled = false;

  @observable nationalities: ValueLabelPair[] = [];
  @observable dialingCodes: ValueLabelPair[] = [];
  @observable displayedBedGroups: ValueLabelPair[] = [];

  @observable genderList: ValueLabelPair[] = [
    {
      label: 'Male',
      value: 'Male',
    },
    {
      label: 'Female',
      value: 'Female',
    },
  ];

  @observable form = {
    bedGroup: {
      description: '',
      id: '',
      token: '',
    },
    guests: [],
    booking: {
      firstName: '',
      lastName: '',
      email: '',
      phone: {
        countryCode: 'CN',
        dialingCode: '86',
        number: '',
        countryName: '中国',
      },
    },
  };

  rooms = [];
  countries: Country[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  onLoad = async () => {
    this.setPagetitle();
    hotelSearchComponentStore.setFromURL();
    this.loadData();
  };

  @action
  onUnload = () => {
    this.isLoadingBtn = false;
  };

  @action
  onContinue = async () => {
    if (this.submitDisabled) return;

    this.message = '';
    this.showMessage = false;

    // 1. form validation
    const formValidationResult = await validate(this.form, validationRules);

    this.hasMissingFields =
      JSON.stringify(formValidationResult || {}).indexOf('"hasError":true') >=
      0;

    this.validationResults = formValidationResult;

    console.info(
      '....onContinue...hasMissingFields...',
      JSON.parse(JSON.stringify(this.form)),
      this.hasMissingFields,
      '...formValidationResult...',
      formValidationResult,
    );

    if (this.hasMissingFields) {
      return;
    }

    // 2. checkout validation
    this.isLoadingBtn = true;

    // 3. checkout
    try {
      const checkoutResult = await this.checkout();

      const paymentURL = `${
        hotelGlobalStore.paymentURL
      }?data=${encodeURIComponent(checkoutResult.redirectUri)}`;
      LogConsole.debug(
        `...guest-detail payment...checkoutResult.redirectUri: ${checkoutResult.redirectUri}  paymentURL:${paymentURL}`,
      );
      this.isLoadingBtn = false;

      navigateWMPPage(paymentURL);
    } catch (error) {
      this.isLoadingBtn = false;
      LogConsole.error(error);
    }
  };

  async checkout() {
    const checkoutPayload: Hotel.CheckOutPayload =
      await this.composeCheckoutPayload();
    return await HotelApi.checkoutHotel<any>(checkoutPayload);
  }

  @action
  getSSOInfo = async () => {
    //fresh token
    const tokenInfo = await userStore.getFlushedTokenInfo();
    const apiKey = await CommonApi.SSOAuth.getApiKey();

    return {
      ssoId: userStore.user.id,
      bigLoyaltyId: userStore.user.loyaltyId,
      ssoDetails: {
        accessToken: tokenInfo.accessToken,
        refreshToken: tokenInfo.refreshToken,
        origin: window.location.origin,
        clientId: Config.HOTEL.CLIENT_ID,
        apiKey: apiKey,
      },

      contactDetails: {
        mobilePhone: this.form.booking.phone.number,
        phoneNumberVerified: true,
        dialingCode: this.form.booking.phone.dialingCode,
      },

      customerDetails: {
        username: userStore.user.username,
        title: userStore.user.title,
        firstName: userStore.user.firstName,
        lastName: userStore.user.lastName,
        gender: userStore.user.gender,
        userId: userStore.user.id,
        loyaltyId: userStore.user.loyaltyId,
        customerNumber: null,
        ssoStatus: 'active',
        paxType: 'ADT',
        ssoId: userStore.user.id,
      },
      otherDetails: {
        notificationPreference: true,
      },
    };
  };

  async composeCheckoutPayload() {
    let ssoInfo = null;
    if (userStore.isLogin) {
      ssoInfo = await this.getSSOInfo();
    }

    const checkoutData = {
      locale: userStore.locale,
      requestedCurrency: userStore.currency,
      billingContactInfo: {
        firstName: this.form.booking.firstName,
        lastName: this.form.booking.lastName,
        email: this.form.booking.email,
        phone: {
          countryCode: this.form.booking.phone.dialingCode,
          number: this.form.booking.phone.number,
        },
        userCulture: userStore.locale,
      },
      ssoInfo,
      orderItems: [
        {
          hotelId: this.hotelId,
          roomId: this.roomId,
          rateId: this.rateId,
          token: this.form.bedGroup.token,
          checkin: hotelSearchComponentStore.checkin,
          checkout: hotelSearchComponentStore.checkout,
        },
      ],
      passengerDetails: this.form.guests.map((g, i) => {
        return {
          firstName: g.firstName,
          lastName: g.lastName,
          nationality: g.nationality,
          'room-number': i + 1,
        };
      }),
      otherAttributes: null,
    };

    const firstOrderItem = {
      type: HOTEL_ORDER_TYPE_HOTEL,
      occupancyPerRoom: hotelSearchComponentStore.roomOccupancy.map((occ) => {
        const childStr = occ.child
          ? `(${new Array(occ.child).fill('17').join(',')})`
          : ``;
        return `${occ.adult}${childStr}`;
      }),
      gaId: Config.HOTEL.GAID,
      selectBigPoints: false,
      buyNowPayLater: false,
      id: HOTEL_ORDER_ITEMS_ID,
    };

    checkoutData.orderItems[0] = {
      ...firstOrderItem,
      ...checkoutData.orderItems[0],
    };

    const defaultParam = {
      orderType: HOTEL_ORDER_TYPE_HOTEL,
      clientInfo: {
        gaId: Config.HOTEL.GAID,
        platform: 'WEB',
      },
      geoId: 'IN',
      channelHash: null,
      clientId: Config.HOTEL.CLIENT_ID,
      paymentAttributes: {
        paymentMode: 'AUTH_CAPTURE',
        delayHours: Config.HOTEL.DELAYHOURS,
      },
      tempAttributes: {
        confirmationUrl: `${window.location.origin}/hotel/confirmation/`,
      },
    };
    return { ...defaultParam, ...checkoutData };
  }

  @action
  async validate(path: string) {
    const result = await validate(this.form, validationRules, path);

    const results = deepMerge(this.validationResults, result);

    this.hasMissingFields =
      JSON.stringify(results || {}).indexOf('"hasError":true') >= 0;

    this.validationResults = results;
  }

  @action
  dialingCodeChanged = async (value) => {
    this.form.booking.phone.dialingCode = value;
    const contry = this.countries.find((f) => f.dialingCode === value);
    this.form.booking.phone.countryCode = contry?.countryCode;
    this.form.booking.phone.countryName = contry?.name;

    this.validate('booking.phone.dialingCode');
  };

  resetData = async () => {
    this.room = this.rooms.find((r) => r.id === this.roomId);
    this.rate = this.room?.rates.find(
      (r) => r.id === this.rateId && r.rateOptionId === this.rateOptionId,
    );

    this.form.bedGroup =
      this.rate?.bedGroups?.find((bed) => bed.id === this.bedGroupId) || {};

    this.totalNights = countNights(
      hotelSearchComponentStore.checkin,
      hotelSearchComponentStore.checkout,
    );

    this.displayedBedGroups = this.rate?.bedGroups?.map((b) => {
      return {
        tag: b,
        value: b.id,
        label: b.description,
      };
    });

    this.form.guests = [];
    for (let index = 0; index < hotelSearchComponentStore.totalRooms; index++) {
      this.form.guests.push({
        firstName: '',
        lastName: '',
        nationality: index === 0 ? 'CN' : '',
      });
    }

    if (userStore.isLogin) {
      this.form.guests[0].firstName = userStore.user.firstName;
      this.form.guests[0].lastName = userStore.user.lastName;
      this.form.booking.firstName = userStore.user.firstName;
      this.form.booking.lastName = userStore.user.lastName;
      this.form.booking.email = userStore.user.username;
      this.form.booking.phone.dialingCode = userStore.user.dialingCode;
      this.form.booking.phone.number = userStore.user.phoneNumber;
    }

    const country = this.countries.find(
      (f) => f.dialingCode === this.form.booking.phone.countryCode,
    );

    if (country) {
      this.form.guests[0].nationality = country.countryCode;
      this.form.booking.phone.countryCode = country.countryCode;
      this.form.booking.phone.countryName = country.name;
      this.form.booking.phone.dialingCode = country.dialingCode;
    }

    // caculate firstPenaltyEndDate
    const { cancelPenalties = [] } = this.rate;
    const [firstPenalty = { penalty_type: CANCELLATION_PENALTY_TYPES.FULL }] =
      cancelPenalties;

    let { end: firstPenaltyEndDate } = firstPenalty;

    const timePlus = ' 23:59:00';

    if (firstPenaltyEndDate && firstPenaltyEndDate.length === 10) {
      //cancel penalties of Agoda always return yyyy-mm-dd
      firstPenaltyEndDate = firstPenaltyEndDate + timePlus;
    } else if (firstPenaltyEndDate && firstPenaltyEndDate.length === 16) {
      const firstPenaltyEndDateSplit = firstPenaltyEndDate.split(' ');
      //cancel penalties of GoQuo always return yyyy-mm-dd hh:mm
      firstPenaltyEndDate = firstPenaltyEndDateSplit[0] + timePlus;
    } else {
      firstPenaltyEndDate = firstPenaltyEndDate?.split('+')[0];
    }

    this.firstPenaltyEndDate = firstPenaltyEndDate;
  };

  loadData = async () => {
    this.rooms = [];
    this.hotelContent = null;
    this.isLoading = true;
    this.hasError = false;

    try {
      await this.getDestDetail();
      await this.getCountries();
      await Promise.all([this.loadHotelContent(), this.loadRooms()]);
      this.resetData();
    } catch (error) {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  };

  getCountries = async () => {
    try {
      const result = await StaticApi.getCountries<any>(userStore.locale);
      const { countries = [] } = result;

      this.nationalities = countries.map((m) => {
        return {
          value: m.countryCode,
          label: m.name,
        };
      });

      this.dialingCodes = countries.map((m) => {
        return {
          value: m.dialingCode,
          country: m.name,
        };
      });

      this.countries = countries;
    } catch (error) {
      console.log(error);
    }
  };

  getDestDetail = async () => {
    try {
      const result = await HotelApi.getDestDetail<any>({
        language: userStore.locale,
        locationId: hotelSearchComponentStore.locationId,
      });

      this.countryCode = result.country_code;
      this.location = result.name;
    } catch (error) {
      //
    }
  };

  loadHotelContent = async () => {
    const hotelContent = await HotelApi.getHotelContent<any>(
      this.hotelId,
      userStore.locale,
    );

    this.hotelContent = transformHotelContentData(hotelContent);
  };

  loadRooms = async () => {
    const tokenInfo = await userStore.getFlushedTokenInfo();

    const roomData = await HotelApi.getRooms<any>(
      this.hotelId,
      {
        countryCode: this.countryCode,
        occupancy: getOccupancyArray(hotelSearchComponentStore.roomOccupancy),
        currency: userStore.currency,
        language: userStore.locale,
        checkin: hotelSearchComponentStore.checkin,
        checkout: hotelSearchComponentStore.checkout,
      },
      tokenInfo,
    );
    this.rooms = transformHotelRoomsData(
      roomData,
      userStore.locale,
      userStore.isLogin,
      hotelSearchComponentStore.checkout,
    );
  };

  setPagetitle() {
    document.title = t.hotel.tripDetails.header;
  }
}
