import { action, makeObservable, observable, computed } from 'mobx';
import { ZodiacApi } from '@/api';
import { userStore } from '@/store/base';
import {
  getComponentData,
  mapSelectionToPlainAddonItems,
  calculateTotalPrice,
} from './parse';

export default class AncillaryInsuranceStore implements ComponentStore {
  @observable tripsInfo: any = null;
  @observable insuranceData: AACheckout.InsuranceComponentData;
  @observable checkoutData: any = null;
  paxTitles: AACheckout.PaxTitle[];
  @observable selectedData: AACheckout.SelectedInsurance[];
  departDesignator: any = null;
  originResponse: any = null; //response data
  isDataLoaded = false;
  isFlushing = false;
  @observable isShow = false;
  @observable plainAddonItems: AACheckout.PlainAddonItem[] = [];
  convertCurrency: (amount: number) => number;

  constructor() {
    makeObservable(this);
  }
  onMount = async () => {};
  onUnmount = async () => {};

  loadData = async () => {
    try {
      if (this.isFlushing) {
        return;
      }
      this.isFlushing = true;

      //1. get baggage data
      await this.loadDataWith();

      //2. parse baggage data
      this.parseData();

      this.isFlushing = false;
    } catch (e) {
      console.error(e);
    }
  };

  @action
  loadDataWith = async () => {
    if (this.isDataLoaded) {
      return;
    }

    const tokenInfo = await userStore.getFlushedTokenInfo();
    const insuranceeData = { tripIds: this.tripsInfo.tripIds, ...tokenInfo };
    const res = await ZodiacApi.getAncillaryInsurance(insuranceeData);

    this.isDataLoaded = true;
    this.originResponse = res;
  };

  @action
  parseData = () => {
    if (!this.convertCurrency || !this.originResponse) {
      return;
    }

    const parsedData = getComponentData(
      this.originResponse,
      this.convertCurrency,
    );

    this.insuranceData = parsedData;
    this.isShow = true;
  };

  @action
  onSelectedInsurance = (
    insurances: AACheckout.SelectedInsurance[] | AACheckout.SelectedInsurance,
  ) => {
    if (Array.isArray(insurances)) {
      this.selectedData = insurances;
    } else {
      this.selectedData = insurances ? [insurances] : null;
    }

    this.plainAddonItems = this.generatePlainAddonItems();
  };

  generatePlainAddonItems = (): AACheckout.PlainAddonItem[] => {
    if (!this.selectedData) {
      return [];
    }

    const addonItems = mapSelectionToPlainAddonItems(
      this.selectedData,
      this.paxTitles,
      this.departDesignator,
      this.convertCurrency,
    );

    return addonItems;
  };

  generateCheckoutAddonItems = () => {
    return this.plainAddonItems.map((addonItem) => {
      return {
        id: addonItem.id,
        category: addonItem.category,
        designator: {
          departureStation: addonItem.departureStation,
          arrivalStation: addonItem.arrivalStation,
          departureTime: null,
          arrivalTime: null,
          departureTimeUtc: null,
          arrivalTimeUtc: null,
          arrivalTerminal: null,
          departureTerminal: null,
        },
        properties: addonItem.properties,
        passengerReferenceId: addonItem.passengerReferenceId,
      };
    });
  };

  // total price
  @computed
  get totalAmount() {
    if (!this.selectedData) {
      return 0;
    }

    const totalAmount = calculateTotalPrice(this.selectedData);

    return totalAmount;
  }
}
