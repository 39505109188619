import i18n from '@/helper/i18n';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getGuestDisplayName } from './guests';

dayjs.extend(customParseFormat);
const t = i18n.chain;

function validateDuplicatedByKey(
  guests: AACheckout.Passenger[],
  getKey: (guest: AACheckout.Passenger) => string,
  errorMessage: ({ names }) => string,
) {
  const keys = guests.map(getKey);

  const duplicatedKeys = guests
    .map(getKey)
    .filter((key, i) => key.replaceAll('&', '') && keys.indexOf(key) !== i);

  const invalidPassengers = guests
    .filter((g) => duplicatedKeys.includes(getKey(g)))
    .map(getGuestDisplayName);

  if (invalidPassengers.length > 0) {
    throw new Error(
      errorMessage({
        names: invalidPassengers.join('、'),
      }),
    );
  }
}

function validateDateByField(
  guests: AACheckout.Passenger[],
  field: string,
  errorMessage: ({ names }) => string,
) {
  const dateFormation = 'DD/MM/YYYY';

  const invalidPassengers = guests
    .filter(
      (g, i) =>
        g.formData[field] &&
        !dayjs(g.formData[field], dateFormation, true).isValid(),
    )
    .map(getGuestDisplayName);

  if (invalidPassengers.length > 0) {
    throw new Error(
      errorMessage({
        names: invalidPassengers.join('、'),
      }),
    );
  }
}

function validateDates(guests: AACheckout.Passenger[]) {
  validateDateByField(
    guests,
    'dateOfBirth',
    t.flights.checkout.errors.dateOfBirthError,
  );
  validateDateByField(
    guests,
    'expiryDate',
    t.flights.checkout.errors.expiryDateError,
  );
}

function validateDuplicates(guests: AACheckout.Passenger[]) {
  validateDuplicatedByKey(
    guests,
    (g) => `${g.formData.docNumber}`.toLowerCase(),
    t.flights.checkout.errors.duplicatedTravelDocError,
  );

  validateDuplicatedByKey(
    guests,
    (g) =>
      `${g.formData.familyName}&${g.formData.givenName}&${g.formData.dateOfBirth}`.toLowerCase(),
    t.flights.checkout.errors.duplicatedNameAndDOBError,
  );
}

export function applyAdditionalValidation(guests: AACheckout.Passenger[]) {
  validateDates(guests);
  validateDuplicates(guests);
}
