import { BaseLogHelper, Response } from './base/base.log.helper';

export class AddonsLog extends BaseLogHelper {
  static logID = 'addons-flow';

  static logFlightDetailsSuccess(request, response: Response) {
    this.logInfo({
      msg: `${this.logID}: flight details succeeded`,
      extra: this.getCallingDetail(request, response),
    });
  }

  static logFlightDetailsFailure(request, response: Response) {
    this.logError({
      msg: `${this.logID}: flight details failed`,
      extra: this.getCallingDetail(request, response),
    });
  }

  static logCheckoutSuccess(request, response: Response) {
    this.logInfo({
      msg: `${this.logID}: checkout succeeded`,
      extra: this.getCallingDetail(request, response),
    });
  }

  static logCheckoutFailure(request, response: Response) {
    this.logError({
      msg: `${this.logID}: checkout failed`,
      extra: this.getCallingDetail(request, response),
    });
  }
}
