import http from '@/api/base/http';
import Config from '@/config';
import ParamManager from '@/helper/param.manager';

interface LogData {
  msg: string;
  route?: string;
  openId?: string;
  extra?: any;
}

const LogAPI = {
  async error(e: LogData) {
    return await this._saveLog(e, 'error');
  },
  async info(e: LogData) {
    return await this._saveLog(e, 'info');
  },
  async warn(e: LogData) {
    return await this._saveLog(e, 'warn');
  },
  async _saveLog<T>(e: LogData, type: string) {
    const eData = await this._addOpenId(e);

    return http.post<T>(`${Config.WMP.BUSINESS_URL}/new-log/${type}`, eData, {
      headers: {
        'bg-key': ParamManager.getBgKey(),
      },
    });
  },
  async _addOpenId(e: LogData) {
    if (e && typeof e === 'object' && !e.openId) {
      e.openId = ParamManager.getOpenId();
    }
    return e;
  },
};

export default LogAPI;
