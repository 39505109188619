import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Store, StoreProvider } from '@/store';

const strictMode = process.env.REACT_APP_STRICTMODE === 'true';
const Root = (
  <StoreProvider value={Store}>
    <App />
  </StoreProvider>
);

const renderNode = (strictMode && (
  <React.StrictMode>{Root}</React.StrictMode>
)) || <>{Root}</>;

ReactDOM.render(renderNode, document.getElementById('root') as HTMLElement);

reportWebVitals();
