import { Select } from '@airasia/phoenix-components/components/Select';
import { action, observable, makeObservable } from 'mobx';
import { CommonApi, DemoApi } from '@/api';
import AStore from './a';
import BStore from './b';
import { json } from 'stream/consumers';

const BAGGAGE_DATA = [
  [
    {
      baggageHeadingColor: 'secondaryBlue',
      baggageList: [
        {
          amount: 0,
          currency: 'MYR',
          id: 'CBWT',
          isIncluded: true,
          isPreSelected: false,
          title: [
            {
              dimension: '',
              weight: '7 kg',
            },
          ],
        },
        {
          amount: 97.02,
          currency: 'MYR',
          id: 'CBWT',
          isIncluded: false,
          isPreSelected: false,
          title: [
            {
              dimension: '',
              weight: '7 kg',
            },
          ],
        },
      ],
      baggageType: 'handCarry',
      baggageTypeColor: 'secondaryLight',
      showToolTip: true,
      toolTipColor: 'secondaryBlue',
      toolTipContent: [
        {
          content: [
            '1 piece only.',
            'Dimension must be within 56 cm (H) x 36 cm (W) x 23 cm (D).',
            'Must fit in the overhead compartment in the aircraft cabin.',
          ],
          type: 'list',
        },
        {
          ctaLink:
            'https://support.airasia.com/s/article/What-are-the-rules-for-cabin-baggage-on-board?language=en_GB',
          ctaText: 'Read More',
          type: 'link',
        },
      ],
      toolTipTitle: 'Carry-on Baggage',
    },
    {
      baggageHeadingColor: 'blueMedium',
      baggageList: [
        {
          amount: 426.8,
          currency: 'MYR',
          id: 'PBAA',
          isIncluded: false,
          isPreSelected: false,
          title: [
            {
              dimension: '',
              weight: '15 kg',
            },
          ],
        },
        {
          amount: 853.6,
          currency: 'MYR',
          id: 'PBAB',
          isIncluded: false,
          isPreSelected: true,
          title: [
            {
              dimension: '',
              weight: '20 kg',
            },
            {
              dimension: '',
              weight: '20 kg',
            },
          ],
        },
      ],
      baggageType: 'checkedBaggage',
      baggageTypeColor: 'blueActive',
      showToolTip: true,
      toolTipColor: 'blueMedium',
      toolTipContent: [
        {
          content: [
            'Each piece must weigh below 20 kg.',
            'Fees are higher at the airport compared to pre-booking now.',
          ],
          type: 'list',
        },
        {
          ctaLink:
            'https://support.airasia.com/s/article/What-I-need-to-know-about-checked-baggage?language=en_GB',
          ctaText: 'Read More',
          type: 'link',
        },
      ],
      toolTipTitle: 'Checked Baggage',
    },
    {
      baggageHeadingColor: 'yellow',
      baggageList: [
        {
          amount: 426.8,
          currency: 'MYR',
          id: 'PSEA',
          isIncluded: false,
          isPreSelected: false,
          title: [
            {
              dimension: '',
              weight: '15 kg',
            },
          ],
        },
        {
          amount: 853.6,
          currency: 'MYR',
          id: 'PSEB',
          isIncluded: false,
          isPreSelected: false,
          title: [
            {
              dimension: '',
              weight: '20 kg',
            },
          ],
        },
        {
          amount: 953.6,
          currency: 'MYR',
          id: 'PSEC',
          isIncluded: false,
          isPreSelected: true,
          title: [
            {
              dimension: '',
              weight: '25 kg',
            },
          ],
        },
        {
          amount: 1053.6,
          currency: 'MYR',
          id: 'PSED',
          isIncluded: false,
          isPreSelected: false,
          title: [
            {
              dimension: '',
              weight: '30 kg',
            },
          ],
        },
        {
          amount: 1153.62,
          currency: 'MYR',
          id: 'PSEF',
          isIncluded: false,
          isPreSelected: false,
          title: [
            {
              dimension: '',
              weight: '40 kg',
            },
          ],
        },
      ],
      baggageType: 'sportsEquip',
      baggageTypeColor: 'yellowLight',
      showToolTip: true,
      toolTipColor: '',
      toolTipContent: [
        {
          content: [
            {
              icon: 'SportsGolf',
              translationKey: 'golf',
            },
            {
              icon: 'SportsSki',
              translationKey: 'skisSnowboard',
            },
            {
              icon: 'SportsSurfing',
              translationKey: 'surfBoard',
            },
            {
              icon: 'SportsCricket',
              translationKey: 'cricket',
            },
            {
              icon: 'SportsBicycle',
              translationKey: 'bicycle',
            },
            {
              icon: 'SportsDiving',
              translationKey: 'divingGear',
            },
          ],
          type: 'icons',
        },
        {
          content: [
            'You can check in any other sport equipment not stated here as part of your checked baggage allowance.',
          ],
          type: 'text',
        },
        {
          ctaLink:
            'https://support.airasia.com/s/article/Flying-with-sports-equipments?language=en_GB',
          ctaText: 'Read More',
          type: 'link',
        },
      ],
      toolTipTitle: 'Sports Equipment includes:',
    },
  ],
];

export default class DemoStore implements PageStore {
  aStore: AStore = new AStore();
  bStore: BStore = new BStore();

  @observable testName = '';
  @observable apiKey = '';
  @observable xcsTraceId = '';
  @observable selectedBaggage = null;
  @observable baggageData = [];
  @observable isShow = false;

  constructor() {
    makeObservable(this);
  }

  getName = () => {};

  @action
  onLoad = async () => {
    console.info('...in DemoStore onLoad....');
    try {
      //const apiKey = await CommonApi.SSOAuth.getApiKey();
      //this.apiKey = apiKey;

      // await this.searchFlight();
      this.setBaggageData();
    } catch (e) {
      console.error('...DemoStore apiKey error ...', e);
    }
  };

  @action
  setBaggageData = async () => {
    this.baggageData = BAGGAGE_DATA;
    this.isShow = true;

    console.info('...in setBaggageData..isShow....', this.isShow);
  };

  @action
  searchFlight = async () => {
    const result = await DemoApi.testZodiacSearch();
    this.xcsTraceId = result.xcsTraceId;
  };

  @action
  onUnload = () => {};

  get selectBaggage() {
    return JSON.parse(JSON.stringify(this.selectedBaggage));
  }

  get aStoreData() {
    return this.aStore.someData;
  }

  @action
  handleBaggage = (data) => {
    console.info('....handleBaggage data...', JSON.stringify(data));
    this.selectedBaggage = data;
  };
}
