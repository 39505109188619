import dayjs from 'dayjs';

const mapGuestTypes = {
  infant: 'Infant',
  child: 'Child',
  adult: 'Adult',
};

const getPaxType = (dateOfBirth, depatureDate, passengerDefinitions) => {
  const convertDate = (origin) =>
    origin
      ? `${origin.substring(4, 8)}-${origin.substring(2, 4)}-${origin.substring(
          0,
          2,
        )}`
      : '';

  const infantMax = passengerDefinitions.infant.maxAge || 2;
  const childMax = passengerDefinitions.child.maxAge || 12;

  const dobObj = dayjs(convertDate(dateOfBirth));
  const depObj = dayjs(depatureDate);
  if (dobObj.add(infantMax, 'years').isAfter(depObj)) {
    return 'infant';
  } else if (dobObj.add(childMax, 'years').isAfter(depObj)) {
    return 'child';
  } else {
    return 'adult';
  }
};

export function getFamilyAndFriends(
  favoriteMembers,
  userDetails,
  depatureDate,
  passengerDefinitions,
): AACheckout.FamilyAndFriend[] {
  const convertDate = (origin) =>
    origin
      ? `${origin.substring(0, 2)}/${origin.substring(2, 4)}/${origin.substring(
          4,
          8,
        )}`
      : '';

  const self: AACheckout.FamilyAndFriend = userDetails
    ? {
        bigMemberId: userDetails.id,
        givenName: userDetails.firstName,
        paxType: 'adult',
        guestType: mapGuestTypes['adult'],
        isSelected: true,
        surname: userDetails.lastName,
        nationality: userDetails.nationality,
        gender:
          userDetails.gender.charAt(0).toUpperCase() +
          userDetails.gender.slice(1),
        dateOfBirth: userDetails.dateOfBirth
          ? convertDate(userDetails.dateOfBirth)
          : '',
        docNumber: '',
        expiryDate: '',
        email: userDetails.username,
        loyaltyId: userDetails.loyaltyId,
        loyaltyTier: userDetails.loyaltyTier,
      }
    : null;

  if (!(favoriteMembers && favoriteMembers.length > 0)) {
    return self ? [self] : [];
  }

  const familyAndFriends: AACheckout.FamilyAndFriend[] = favoriteMembers.map(
    (m) => {
      const gender = m.gender.charAt(0).toUpperCase() + m.gender.slice(1);
      const dateOfBirth = convertDate(m.dateOfBirth);
      const expiryDate = convertDate(m.expirationDate);
      const paxType = getPaxType(
        m.dateOfBirth,
        depatureDate,
        passengerDefinitions,
      );

      return {
        bigMemberId: m.id,
        givenName: m.firstName,
        paxType,
        guestType: mapGuestTypes[paxType],
        isSelected: false,
        surname: m.lastName,
        nationality: m.nationality,
        gender,
        dateOfBirth,
        docNumber: m.docNo,
        expiryDate,
        email: '',
        loyaltyId: '',
        loyaltyTier: '',
      };
    },
  );

  return self ? [self, ...familyAndFriends] : familyAndFriends;
}
