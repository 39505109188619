import { action, makeObservable, observable } from 'mobx';
import { ZodiacApi } from '@/api';
import { userStore } from '@/store/base';
import globalLoadingStore from '@/store/common/global-loading';

import { MealParser, BundleMealParser } from './parse';

export default class AncillaryMealStore {
  @observable mealData: AACheckout.MealData = [];
  @observable startPrice: number | null = null;
  @observable totalAmount = 0;
  @observable plainAddonItems: AACheckout.PlainAddonItem[] = [];
  @observable bundlePlainAddonItems: AACheckout.PlainAddonItem[] = [];
  @observable selectedMeals: any[] = [];

  @observable forceUpdateShow = true;
  @observable dataLoading = false;
  @observable isDataLoaded = false;

  @observable isBundleForceToggle = false;

  @observable isBundleSelected: boolean;
  @observable bundleSelectedByJourney: boolean[];
  @observable includedIcon: string;
  @observable bundleMealsData: AACheckout.MealData;

  tripsInfo: TripsInfo = null;
  paxTitles: AACheckout.PaxTitle[];
  conversionRates: AACheckout.ConversionRates;

  constructor() {
    makeObservable(this);
  }

  @action
  loadData = async () => {
    if (
      !(this.paxTitles && this.paxTitles.length > 0) ||
      !this.tripsInfo ||
      !(
        this.conversionRates &&
        Object.getOwnPropertyNames(this.conversionRates).length > 0
      )
    ) {
      return;
    }

    if (this.isDataLoaded || this.dataLoading) {
      return;
    }

    this.dataLoading = true;

    const tokenInfo = await userStore.getFlushedTokenInfo();
    const originalPageData = await ZodiacApi.getPageData(
      this.tripsInfo.tripIds,
      this.tripsInfo.airlineProfile,
      tokenInfo,
      ['meal'],
      ['ancillary'],
    );

    const { startPrice, mealData } = new MealParser(
      originalPageData,
      this.conversionRates,
    ).parse();

    this.startPrice = startPrice;
    this.mealData = mealData;

    this.dataLoading = false;
    this.isDataLoaded = true;
  };

  @action
  onSelectedMeal = (selectedMealData) => {
    this.handleSelection(selectedMealData);
  };

  @action
  handleBundleValid = async (isBundleValid) => {
    this.isBundleForceToggle = !isBundleValid;
  };

  @action
  onModelOpenedCallback = async () => {
    globalLoadingStore.close();
  };

  @action
  private handleSelection = (selectedMealData) => {
    const { totalAmount, selectedMeals } = selectedMealData;

    this.selectedMeals = selectedMeals || [];
    this.totalAmount = totalAmount;

    const plainAddonItems = this.generatePlainAddonItems(selectedMeals);

    this.plainAddonItems = plainAddonItems.filter((p) => !p.isBundled);
    this.bundlePlainAddonItems = plainAddonItems.filter((p) => p.isBundled);
  };

  private generatePlainAddonItems = (selectedMeals) => {
    const items: AACheckout.PlainAddonItem[] = [];
    const convertItem: (
      meal: any,
    ) => Omit<
      AACheckout.PlainAddonItem,
      'departureStation' | 'arrivalStation' | 'passengerReferenceId'
    > = (meal) => ({
      id: meal.id,
      groupBy: meal.id,
      currency: meal.currency,
      name: meal.name,
      price: meal.price,
      type: 'meal',
      category: 'Meal',
      properties: {
        name: meal.name,
        isVeg: meal.isVeg,
      },
    });

    // need to loop from the complicated data structure.
    for (const segment of selectedMeals) {
      const { departureStation, arrivalStation, meals = [] } = segment;

      for (let index = 0; index < meals.length; index++) {
        const mealGroup = meals[index];

        for (const meal of mealGroup) {
          const { complimentaryDrinks = [], addonDesserts = [] } = meal;

          items.push({
            ...convertItem(meal),
            departureStation,
            arrivalStation,
            passengerReferenceId: this.paxTitles[index].paxId,
            isBundled: meal.withBundle,
          });

          for (const drink of complimentaryDrinks) {
            if (drink.isSelected) {
              items.push({
                ...convertItem(drink),
                departureStation,
                arrivalStation,
                passengerReferenceId: this.paxTitles[index].paxId,
                isBundled: meal.withBundle,
              });
            }
          }

          for (const dessert of addonDesserts || []) {
            if (dessert.isSelected) {
              items.push({
                ...convertItem(dessert),
                departureStation,
                arrivalStation,
                passengerReferenceId: this.paxTitles[index].paxId,
                isBundled: meal.withBundle,
              });
            }
          }
        }
      }
    }

    return items;
  };

  @action
  handleBundleInfluenceData = async (bundleInfluenceData) => {
    const bundleSelectedByJourney = [
      !!(bundleInfluenceData && bundleInfluenceData.departFlight),
      !!(bundleInfluenceData && bundleInfluenceData.returnFlight),
    ];
    const isBundleSelected = bundleSelectedByJourney.some((s) => s);
    const bundleMealsData: AACheckout.MealData = new BundleMealParser(
      bundleInfluenceData,
      this.conversionRates,
    ).parse();

    this.includedIcon = isBundleSelected ? 'StarInvertedIcon' : '';
    this.isBundleSelected = isBundleSelected;
    this.bundleSelectedByJourney = bundleSelectedByJourney;
    this.bundleMealsData = bundleMealsData;

    await this.clearSelection();
    await this.forceUpdate();
  };

  @action
  private clearSelection = async () => {
    this.handleSelection({
      selectedMeals: [],
      totalAmount: 0,
    });
  };

  @action
  private forceUpdate = async () => {
    return new Promise((resolve, _reject) => {
      this.forceUpdateShow = false;
      setTimeout(() => {
        this.forceUpdateShow = true;
        resolve(true);
      }, 1);
    });
  };

  generateCheckoutAddonItems = () => {
    return this.plainAddonItems.map((addonItem) => {
      return {
        id: addonItem.id,
        category: addonItem.category,
        designator: {
          departureStation: addonItem.departureStation,
          arrivalStation: addonItem.arrivalStation,
          departureTime: null,
          arrivalTime: null,
          departureTimeUtc: null,
          arrivalTimeUtc: null,
          arrivalTerminal: null,
          departureTerminal: null,
        },
        properties: addonItem.properties,
        passengerReferenceId: addonItem.passengerReferenceId,
      };
    });
  };
}
