import i18n from '@/helper/i18n';
import { DEFAULT_NATIONALITY, DEFAULT_TRAVEL_DOC_TYPES } from '../consts';
import { travelDocIsAvailable } from './travel-docs';

const t = i18n.chain;

const passengerType = {
  adult: 'adt',
  child: 'chd',
  infant: 'inft',
};

export const getGuests = (paxDetails, passengerDefinitions, tripsInfo) => {
  const guests: AACheckout.Passenger[] = [];
  const { airlineProfile } = tripsInfo;

  paxDetails.map((p) => {
    for (let index = 0; index < Number(p.count); index++) {
      const paxPassportDef = passengerDefinitions[p.paxType].documents.find(
        (d) => d.type === DEFAULT_TRAVEL_DOC_TYPES[0],
      );

      const paxIdDef = passengerDefinitions[p.paxType].documents.find(
        (d) => d.type === DEFAULT_TRAVEL_DOC_TYPES[1],
      );
      const supportedDocTypes = [];

      if (
        paxPassportDef &&
        travelDocIsAvailable(airlineProfile, paxPassportDef)
      ) {
        supportedDocTypes.push(paxPassportDef);
      }

      if (paxIdDef && travelDocIsAvailable(airlineProfile, paxIdDef)) {
        supportedDocTypes.push(paxIdDef);
      }

      const selectedDocType =
        supportedDocTypes.length > 0 ? supportedDocTypes[0]['type'] : '';

      guests.push({
        paxType: p.paxType,
        seq: guests.length + 1,
        subSeq: index + 1,
        hasError: false,
        referenceId: `pax_${passengerType[p.paxType]}_${index}`,
        infantRef: '',
        supportedDocTypes,
        syncId: '',
        validateOnload: false,
        triggerBigpointsCalc: false,
        email: '',
        loyaltyId: '',
        loyaltyTier: '',
        formData: {
          givenName: '',
          familyName: '',
          nationality: DEFAULT_NATIONALITY,
          gender: 'Male',
          dateOfBirth: '',
          type: selectedDocType,
          docNumber: '',
          expiryDate: '',
          travellingWith: '',
          bigMemberId: '',
        },
      });
    }
  });

  guests
    .filter((g) => g.paxType === 'infant')
    .map((g, index) => {
      g.formData.travellingWith = `pax_adt_${index}`;
      guests.find(
        (a) => a.referenceId === g.formData.travellingWith,
      ).infantRef = g.referenceId;
    });

  return guests;
};

export const getGuestsLimit = (guests: AACheckout.Passenger[]) => {
  return {
    adult: guests.filter((g) => g.paxType === 'adult').length,
    child: guests.filter((g) => g.paxType === 'child').length,
    infant: guests.filter((g) => g.paxType === 'infant').length,
  };
};

export function getGuestDisplayName(g: AACheckout.Passenger) {
  return g.formData.familyName || g.formData.givenName
    ? `${g.formData.givenName} ${g.formData.familyName}`
    : `${t.flights.checkout.guestDetailsForm.adult} ${g.subSeq}`;
}
