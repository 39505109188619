import LogAPI from './log.api';

interface Request {
  url: string;
  method: string;
  headers: any;
  body: any;
}

export interface Response {
  data: any;
  [key: string]: string;
}

export class BaseLogHelper {
  static logID = '';
  static route = '';

  static setLogger({ logID, route }: { logID: string; route: string }) {
    BaseLogHelper.logID = logID;
    BaseLogHelper.route = route;
  }

  static getCurrentRoute() {
    return BaseLogHelper.route || window.location.pathname;
  }

  static parseResponse(response: Response) {
    return response
      ? {
          status: response.status,
          data: response.data,
          statusText: response.statusText,
        }
      : null;
  }

  static getCallingDetail(request: Request, response: Response) {
    return {
      callingDetail: {
        request,
        response: BaseLogHelper.parseResponse(response),
      },
    };
  }

  static async logInfo({ msg, extra = {} }: { msg: string; extra?: any }) {
    const route = BaseLogHelper.getCurrentRoute();
    LogAPI.info({
      msg,
      route,
      extra,
    });
  }

  static async logError({ msg, extra = {} }: { msg: string; extra?: any }) {
    const route = BaseLogHelper.getCurrentRoute();
    LogAPI.error({
      msg,
      route,
      extra,
    });
  }

  static async logWarn({ msg, extra = {} }: { msg: string; extra?: any }) {
    const route = BaseLogHelper.getCurrentRoute();
    LogAPI.warn({
      msg,
      route,
      extra,
    });
  }
}
