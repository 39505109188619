import { convertMoneyToDefaultCurrency } from '@/helper/util/common';
import { CURRENCY_DEFAULT } from '@/helper/constants';

export class MealParser {
  private pageData;
  private conversionRates;

  constructor(pageData, conversionRates) {
    this.pageData = pageData;
    this.conversionRates = conversionRates;
  }

  public parse() {
    const { ancillary: originalAncillary = {} } = this.pageData;

    const { meal = {} } = originalAncillary;
    const { startPrice = '', data } = meal;

    const mealData: AACheckout.MealData = this.getMealData(data);

    return {
      startPrice,
      mealData,
    };
  }

  getMealData(data): AACheckout.MealData {
    const mealData: AACheckout.MealData = [];

    data.map((s) => {
      const { ancillarylist } = s;

      ancillarylist.map((ancillary) => {
        const {
          complimentaryDrinks: originalComplimentaryDrinks = [],
          maximizeOptions: originalAddonDesserts = [],
          meals: originalMeals = [],
          designator,
        } = ancillary;

        const { arrivalStation, departureStation } = designator;

        const complimentaryDrinks = originalComplimentaryDrinks.map(
          (drink, index) => {
            const { id, price, properties } = drink;
            const { name } = properties;
            const { amount, currency } = price;
            return {
              id,
              isSelected: index === 0,
              name,
              price: convertMoneyToDefaultCurrency(
                amount,
                currency,
                this.conversionRates,
              ),
            };
          },
        );

        const addonDesserts = originalAddonDesserts.map((dessert) => {
          const { id, price, properties } = dessert;
          const { name, imageUrl } = properties;
          const { amount, currency } = price;
          return {
            id,
            isSelected: false,
            imageUrl,
            name,
            price: convertMoneyToDefaultCurrency(
              amount,
              currency,
              this.conversionRates,
            ),
          };
        });

        const meals = originalMeals.map((meal) => {
          const { id, price, properties, rules } = meal;
          const { name, imageUrl, isVeg, limitPerPassenger } = properties;
          const { amount, currency } = price;
          const { isPreselected: isSelected } = rules;
          return {
            addonDesserts,
            complimentaryDrinks,
            id,
            imageUrl,
            isComplimentary: false,
            isSelected,
            name,
            limitPerPassenger: Number(limitPerPassenger),
            price: convertMoneyToDefaultCurrency(
              amount,
              currency,
              this.conversionRates,
            ),
            isVeg,
            currency: CURRENCY_DEFAULT,
            originalPrice: amount,
            originCurrency: currency,
          };
        });

        mealData.push({
          arrivalStation,
          departureStation,
          meals,
        });
      });
    });

    return mealData;
  }
}

export class BundleMealParser {
  private bundleInfluenceData;
  private conversionRates;

  constructor(bundleInfluenceData, conversionRates) {
    this.bundleInfluenceData = bundleInfluenceData;
    this.conversionRates = conversionRates;
  }

  public parse() {
    const { departFlight, returnFlight } = this.bundleInfluenceData || {};
    const departMealData: AACheckout.MealData = this.getMealData(departFlight);
    const returnMealData: AACheckout.MealData = this.getMealData(returnFlight);

    const mealData = [...departMealData, ...returnMealData];
    return mealData.length > 0 ? mealData : null;
  }

  getMealData(data): AACheckout.MealData {
    if (!data) return [];

    const { includedSsrs, bundleId, bundleCode } = data;
    const { journeyDetails } = includedSsrs[0];
    const bundleMealsData: AACheckout.MealData = [];

    journeyDetails.map(({ availableUpgrade, designator }) => {
      const { destination: arrivalStation, origin: departureStation } =
        designator;

      const meals = availableUpgrade.map((meal) => {
        const { id, price, properties, rules } = meal;
        const { name, imageUrl, isVeg, limitPerPassenger } = properties;
        const { amount, currency } = price;
        const { isPreselected: isSelected } = rules;

        return {
          addonDesserts: [],
          complimentaryDrinks: [],
          id,
          imageUrl,
          isComplimentary: false,
          isSelected,
          name,
          limitPerPassenger: Number(limitPerPassenger),
          price: convertMoneyToDefaultCurrency(
            amount,
            currency,
            this.conversionRates,
          ),
          isVeg,
          currency: CURRENCY_DEFAULT,
          originalPrice: amount,
          originCurrency: currency,
          isBundledMeal: true,
          bundleId,
          bundleCode,
        };
      });

      bundleMealsData.push({
        departureStation,
        arrivalStation,
        meals,
      });
    });

    return bundleMealsData;
  }
}
