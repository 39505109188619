import BundleBase from '../common/bundle/bundle.base';
import { AACheckoutConst } from '@/helper/constants';

class BundleInfluence extends BundleBase {
  private originalBaggageResponse: AACheckout.BaggageResponse | null = null;

  constructor() {
    super();
  }

  /**
   * update load data
   */
  updateLoadData(
    baggageResponse: AACheckout.BaggageResponse,
    bundleInfluenceData: AACheckout.BundleInfluenceData,
  ): any {
    const isBundleSelected = this.checkIfBundleSelected(bundleInfluenceData);
    const includedIcon = isBundleSelected ? 'StarInvertedIcon' : '';

    // save original baggageResponse
    if (!this.originalBaggageResponse) {
      this.originalBaggageResponse = baggageResponse;
    }

    // clone baggageResponse
    const clonedBaggageResponse = JSON.parse(JSON.stringify(baggageResponse));

    // update baggageResponse with new bundle influence
    if (bundleInfluenceData?.departFlight?.includedSsrs?.length > 0) {
      this.updateBaggageResponse(
        clonedBaggageResponse.ancillary.baggage.data[0].ancillarylist,
        bundleInfluenceData.departFlight.includedSsrs[0],
      );
    } else {
      // if there is no depart, restore the original data
      clonedBaggageResponse.ancillary.baggage.data[0].ancillarylist =
        this.originalBaggageResponse.ancillary.baggage.data[0].ancillarylist;
    }

    if (bundleInfluenceData?.returnFlight?.includedSsrs?.length > 0) {
      this.updateBaggageResponse(
        clonedBaggageResponse.ancillary.baggage.data[1]?.ancillarylist,
        bundleInfluenceData.returnFlight.includedSsrs[0],
      );
    } else {
      // if there is no return, restore the original data
      if (clonedBaggageResponse.ancillary.baggage.data[1]) {
        clonedBaggageResponse.ancillary.baggage.data[1].ancillarylist =
          JSON.parse(
            JSON.stringify(
              this.originalBaggageResponse.ancillary.baggage.data[1]
                .ancillarylist,
            ),
          );
      }
    }

    return {
      baggageResponse: clonedBaggageResponse,
      isBundleSelected,
      includedIcon,
    };
  }

  updatePlainAddonItems(
    plainAddonItems: AACheckout.PlainAddonItem[],
    bundleInfluenceData: AACheckout.BundleInfluenceData,
  ) {
    const bundledItems = plainAddonItems.filter((item) => {
      const isBundledItem =
        this.checkIfBundled(
          item,
          bundleInfluenceData,
          AACheckoutConst.FlightKeyEnum.DEPART_FLIGHT,
        ) ||
        this.checkIfBundled(
          item,
          bundleInfluenceData,
          AACheckoutConst.FlightKeyEnum.RETURN_FLIGHT,
        );
      if (isBundledItem) {
        item.isBundled = true;
        item.bundleId = isBundledItem.bundleId;
      }
      return item.isBundled;
    });

    const nonBundledItems = plainAddonItems.filter((item) => !item.isBundled);

    return {
      plainAddonItems: nonBundledItems,
      bundlePlainAddonItems: bundledItems,
    };
  }

  private checkIfBundled(
    item: AACheckout.PlainAddonItem,
    bundleInfluenceData: AACheckout.BundleInfluenceData,
    flightType: AACheckoutConst.FlightKeyEnum,
  ): { bundleId: string } | null {
    const flight = bundleInfluenceData[flightType];
    if (!flight) return null;

    const isMatchingFlight =
      flight.designator.departureStation === item.departureStation &&
      flight.designator.arrivalStation === item.arrivalStation;
    if (!isMatchingFlight) return null;

    const isBundled = flight.includedSsrs.some((ssr) => {
      return ssr.ssrCode === item.id;
    });

    return isBundled ? { bundleId: flight.bundleCode } : null;
  }

  private checkIfBundleSelected(
    bundleInfluenceData: AACheckout.BundleInfluenceData,
  ): boolean {
    return (
      bundleInfluenceData &&
      ((bundleInfluenceData.departFlight &&
        bundleInfluenceData.departFlight.includedSsrs.length > 0) ||
        (bundleInfluenceData.returnFlight &&
          bundleInfluenceData.returnFlight.includedSsrs.length > 0))
    );
  }

  private updateBaggageResponse(
    list: AACheckout.BaggageAncillaryList,
    bundleItem: AACheckout.BundleIncludedSsr,
  ) {
    if (!list) return;
    Object.entries(list).forEach(([option, bags]) => {
      if (!bags) return;
      const newList = bags.reduce((acc: any[], bag: any) => {
        return this.updateBagIfEligible(bag, bundleItem, acc);
      }, []);
      if (newList.length) list[option] = newList;
    });
  }

  private updateBagIfEligible(
    bag: any,
    baggageSectionOfBundle: any,
    newList: any[],
  ): any[] {
    const bundleBagOption =
      baggageSectionOfBundle?.journeyDetails?.[0]?.availableUpgrade;
    const optionPresent = bundleBagOption?.find(
      (bundleBag: any) => bundleBag.id === bag.id,
    );

    if (!optionPresent && baggageSectionOfBundle.ssrCode === bag.id) {
      // check for the free baggage included in the bundle (like PBAB in VP)
      bag.rules = { ...bag.rules, withBundle: true, isPreselected: false };
      bag.price.amount = 0;
      newList.push(bag);
    } else if (optionPresent) {
      // if baggage present, update price and properties
      bag.price = optionPresent.price;
      bag.properties = optionPresent.properties;
      bag.rules = { ...bag.rules, isPreselected: false };
      newList.push(bag);
    }
    return newList;
  }
}

export default BundleInfluence;
