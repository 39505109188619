import dayjs from 'dayjs';
import { LogConsole } from '@/helper/log';

export const getNavigatorLocale = () => {
  //use  navigator["xxx"] because typescript shows has not this property
  const locale =
    navigator['userLanguage'] ||
    (navigator.languages &&
      navigator.languages.length &&
      navigator.languages[0]) ||
    navigator.language ||
    navigator['browserLanguage'] ||
    navigator['systemLanguage'];
  return locale.toLowerCase();
};

export const convertToDefaultDate = (date) => {
  return dayjs(date).format('YYYY-MM-DD');
};

export const getBase64Value = (param: string) => {
  if (!param) {
    return undefined;
  }

  try {
    const value = window.atob(param);
    return JSON.parse(value);
  } catch (e) {
    LogConsole.error(e);
    return undefined;
  }
};

/**
 * goto wechat miniprogram page
 */
export const navigateWMPPage = (url) => {
  const wx = (window as any).wx;
  wx.miniProgram.navigateTo({
    url, //
    success: () => {
      console.log('navigate to wechat success');
    },
    fail: (res: any) => {
      console.error('navigate to wechat fail: ', res.errMsg);
    },
  });
};

export const navigateBackWMP = () =>
  (window as any).wx.miniProgram.navigateBack();
