import React from 'react';
import { observer } from 'mobx-react';
import globalLoadingStore from '@/store/common/global-loading';
import Loading from '@/components/loading';

const GlobalAlert: React.FC = observer(() => {
  return globalLoadingStore.loading ? <Loading fullScreen /> : null;
});

export default GlobalAlert;
