import { getCountries } from './countries';
import { getGuests, getGuestsLimit } from './guests';
import { getFamilyAndFriends } from './family-and-friends';

export const transform = async (pageData, tripsInfo) => {
  const {
    paxDetails = [],
    countryList,
    flightDefinitions,
    content,
    bigPointsCalculation,
  } = pageData;

  const { flightDetails } = pageData.flightDetails;
  const { friendsAndFamily: originalFriendsAndFamily, userDetails } =
    pageData.sso;
  const { favoriteMembers = [] } = originalFriendsAndFamily || {};
  const { definitions } = flightDefinitions;
  const { passenger: passengerDefinitions } = definitions;
  const { token: bigPointsCalculationToken } = bigPointsCalculation;

  const departureTime = flightDetails.designator.departureTime;
  const familyAndFriends = getFamilyAndFriends(
    favoriteMembers,
    userDetails,
    departureTime,
    passengerDefinitions,
  );
  const countries = getCountries(countryList);
  const guests = getGuests(paxDetails, passengerDefinitions, tripsInfo);
  const guestsLimit = getGuestsLimit(guests);

  return {
    countries,
    gender: content.gender,
    salutation: content.salutation,
    guests,
    guestsLimit,
    familyAndFriends,
    departureTime,
    bigPointsCalculationToken,
  };
};
